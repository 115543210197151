<template>
	<div style="width:100%; height: 280px;margin: auto" :id="id" ref="echarts"></div>
</template>

<script>
import * as echarts from "echarts";

require("echarts/theme/shine");

export default {
	name: "echartscom",
	props: ["id", "chartData", "nameData"],
	data() {
		return {
			arr: [],
			myChart: ""
		};
	},
	mounted() {
		if (this.myChart != null && this.myChart != "" && this.myChart != undefined) {
			this.myChart.dispose();
		}
		this.myChart = echarts.init(document.getElementById(this.id));
		this.drawCharts();
	},
	methods: {
		drawCharts() {
			let that = this;
			that.myChart.setOption({
				color: ["#19D4AE", "#5AB1EF", "#FFB980", "#0067A6", "#197ad4", "#dc5bf0", "#19b2d4"],
				tooltip: {
					trigger: 'item'
				},
				series: [
					{
						type: "pie",
						radius: "50%",
						// center: ["50%", "50%"],
						// avoidLabelOverlap: false,
						label: {
							position: "outer",
							alignTo: "labelLine",
							bleedMargin: 5,
							// formatter: "{b|{b}} \n {per|{d}%}",
							normal: {
								// show:false,
								formatter: "{b} \n ({d}%)", //设置引导线内容显示的格式
								textStyle: {
									color: "rgba(255,255,255,0.5)",
									fontSize: "12px"
								}
							},
							rich: {
								b: {
									fontSize: 12,
									lineHeight: 33
								},
								//设置百分比数字颜色
								per: {
									color: "#00B4FB",
									fontSize: 18,
									padding: [2, 4],
									borderRadius: 2
								}
							}
						},
						width: "auto",
						// roseType: 'radius',
						animationType: "scale",
						animationEasing: "elasticOut",
						data: this.arr
					}
				]
			});
		},
		initData() {
			this.nameData.forEach((val, i) => {
				// 列数据
				this.chartData.forEach((item, index) => {
					if (i == index) {
						this.arr.push({
							value: item,
							name: val
						});
					}
				});
			});
		}
	},
	watch: {
		chartData: {
			// 在父组件数据发生改变后子组件要做到实时更新,就需要子组件用watch来监听数据的变化
			// 看情况是否需要newValue和oldValue之间值比较
			handler() {
				this.arr.length = 0;
				this.initData();
				this.drawCharts();
			},
			deep: true
		}
	},
	computed: {
		echarts() {
			return "echarts" + Math.random() * 100000;
		}
	},
	created() {
		this.initData();
	}
};
</script>

<style lang="scss" scoped>

</style>
